import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { rhythm } from '../utils/typography';

import { SocialIcon } from 'react-social-icons';
import {
  FacebookShareButton, TwitterShareButton, EmailShareButton, LinkedinShareButton, WhatsappShareButton,RedditShareButton,
} from 'react-share';

const styles = {
  sharing: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    '& div': {
      cursor: 'pointer',
      marginRight: rhythm(0.5),
    },
  },
}

const Share = ({ slug, title, excerpt }) => {
  return (
    <StaticQuery
      query={shareQuery}
      render={data => {
        const { siteUrl, keywords, social } = data.site.siteMetadata
        const url = siteUrl + slug

        return (
          <div css={styles.sharing} >
            <FacebookShareButton url={url} quote={excerpt} hashtag='#wearemcgees'>
              <SocialIcon network="facebook" style={{ height: 32, width: 32 }}/>
            </FacebookShareButton>
            <TwitterShareButton url={url} title={title} via={social.twitter.split('@').join('')} hashtags={keywords} >
              <SocialIcon network="twitter" style={{ height: 32, width: 32 }}/>
            </TwitterShareButton>
            <EmailShareButton url={url} subject={title} >
              <SocialIcon network="email" style={{ height: 32, width: 32 }}/>
            </EmailShareButton>
            <LinkedinShareButton url={url} title={title} description={excerpt} >
              <SocialIcon network="linkedin" style={{ height: 32, width: 32 }}/>
            </LinkedinShareButton>
            <WhatsappShareButton url={url} title={title} >
              <SocialIcon network="whatsapp" style={{ height: 32, width: 32 }}/>
            </WhatsappShareButton>
            <RedditShareButton url={url} title={title} >
              <SocialIcon network="reddit" style={{ height: 32, width: 32 }}/>
            </RedditShareButton>
          </div>
        )
      }}
    />
  )
};

const shareQuery = graphql`
  query ShareQuery {
    site {
      siteMetadata {
        author
        siteUrl
        keywords
        social {
          twitter
        }
      }
    }
  }
`

export default Share;